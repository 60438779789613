import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import ContactForm from "../pages/contact/form";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StockGainerPersonal from "../components/StockGainerPersonal";
import ChartTwo from "../components/ChartTwo";
import ChartBandLight from "../components/ChartBandLight";
import ChartThreeLight from "../components/ChartThreeLight";

import { motion } from "framer-motion";
import { InView} from "react-intersection-observer";

export const ContactTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
<div>
    <Helmet>
      <title>Swiftfox | Contact</title>
    </Helmet>
    <div className="hero-section faq-hero">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Question?<br /><span>We’ve got the answers.</span>
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Our team of experts are here to assist you in finding the very best currency exchange solutions. If you have any questions, we’re here to help, 24 hours a day, seven days a week. 
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button-light" data-srcoll-to to="#help-me">
                    I need help
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item hovering">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.0, ease: "easeInOut" }} className="hero-chart-light pushmeright chart-perspective">
                  <StockGainerPersonal />
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <div className="hero-banner-blue">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }} className="hero-banner-ticker">
                <ChartBandLight />
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="callout-section orange-bg white-text" id="help-me">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="module-stack">
                  <div className="bullet-container">
                    <div className="bullet">
                      <img alt="No Hidden Fees" src="../img/fee.svg" />
                      <p>No Hidden Fees</p>
                    </div>
                    <div className="bullet">
                      <img alt="No Contracts" src="../img/contracts.svg" />
                      <p>No Contracts</p>
                    </div>
                    <div className="bullet">
                      <img alt="No Payment Fees" src="../img/payment.svg" />
                      <p>No Payment Fees</p>
                    </div>
                    <div className="bullet">
                      <img alt="24/7 Support" src="../img/sup.svg" />
                      <p>24/7<br />Support</p>
                    </div>
                    <div className="bullet">
                      <img alt="Account Managers" src="../img/manager.svg" />
                      <p>Dedicated Acc. Managers</p>
                    </div>
                    <div className="bullet">
                      <img alt="Payments" src="../img/fast.svg" />
                      <p>Fast & Easy Int. Payments</p>
                    </div>
                    <div className="bullet">
                      <img alt="Beneficiaries" src="../img/saved.svg" />
                      <p>Pre-Saved Beneficiaries</p>
                    </div>
                    <div className="bullet">
                      <img alt="Trade & Status History" src="../img/history.svg" />
                      <p>Trade Status & History</p>
                    </div>
                    <div className="bullet">
                      <img alt="FCA" src="../img/fca.svg" />
                      <p>FCA<br />Authorised</p>
                    </div>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Are there currencies you can’t exchange?
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  We take our regulatory responsibilities very seriously. In line with our anti-money laundering policy and sanction screening requirements, we cannot receive funds from or send money to the following countries. However, we’re continually looking to expand our capabilities, so this may be subject to change.
<br/><br/>
If you have any questions about making payments to restricted countries, please contact your relationship manager or support team.

                </motion.h4>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>

    </div>

);

ContactTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const Contact = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ContactTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};


Contact.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default Contact;

export const pageQuery = graphql`
  query ContactTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
